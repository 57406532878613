
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 448 512"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"fill":"currentColor","d":"M319.91 292H24a24 24 0 01-24-24v-24a24 24 0 0124-24h295.91l35.66 36z","opacity":".4"}}),_c('path',{attrs:{"fill":"currentColor","d":"M305.44 103.05L441 238.54l.06.06a25.23 25.23 0 010 34.84l-.06.06L305.44 409a24 24 0 01-33.94 0l-17-17a24 24 0 010-33.94L355.57 256 254.5 154a24 24 0 010-33.94l17-17a24 24 0 0133.94-.01z"}})])
          )
        }
      }
    